
// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fap-front-primary: mat.define-palette(mat.$indigo-palette);
$fap-front-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$fap-front-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
// $fap-front-theme: mat.define-light-theme($fap-front-primary, $fap-front-accent, $fap-front-warn);
$fap-front-theme: mat.define-light-theme((
        color: (
          primary: $fap-front-primary,
          accent: $fap-front-accent,
          warning: $fap-front-warn
        ),
        typography: mat.define-typography-config(),
        density: 0,
       ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($fap-front-theme);
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "./assets/scss/theme/variables";
@import "./assets/scss/variables/variables.scss";

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
button {background-color: var(--primary-color);}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif !important; }
span.loader {
    display: flex;
    text-align: center;
    height: 100%;
    align-items: center;
    justify-content: center;
    transform: scale(0.5);
    i {
        overflow: hidden;
        position: relative;
        text-indent: -9999px;
        display: inline-block;
        width: 8px;
        height: 8px;
        background: transparent;
        border-radius: 100%;
        box-shadow: var(--primary-color) 0px 26px 0 6px,
            var(--primary-color) 0.90971px 26.05079px 0 5.93333px,
            var(--primary-color) 1.82297px 26.06967px 0 5.86667px,
            var(--primary-color) 2.73865px 26.05647px 0 5.8px,
            var(--primary-color) 3.65561px 26.01104px 0 5.73333px,
            var(--primary-color) 4.57274px 25.93327px 0 5.66667px,
            var(--primary-color) 5.48887px 25.8231px 0 5.6px,
            var(--primary-color) 6.40287px 25.68049px 0 5.53333px,
            var(--primary-color) 7.31358px 25.50548px 0 5.46667px,
            var(--primary-color) 8.21985px 25.2981px 0 5.4px,
            var(--primary-color) 9.12054px 25.05847px 0 5.33333px,
            var(--primary-color) 10.01448px 24.78672px 0 5.26667px,
            var(--primary-color) 10.90054px 24.48302px 0 5.2px,
            var(--primary-color) 11.77757px 24.1476px 0 5.13333px,
            var(--primary-color) 12.64443px 23.78072px 0 5.06667px,
            var(--primary-color) 13.5px 23.38269px 0 5px,
            var(--primary-color) 14.34315px 22.95384px 0 4.93333px,
            var(--primary-color) 15.17277px 22.49455px 0 4.86667px,
            var(--primary-color) 15.98776px 22.00526px 0 4.8px,
            var(--primary-color) 16.78704px 21.48643px 0 4.73333px,
            var(--primary-color) 17.56953px 20.93855px 0 4.66667px,
            var(--primary-color) 18.33418px 20.36217px 0 4.6px,
            var(--primary-color) 19.07995px 19.75787px 0 4.53333px,
            var(--primary-color) 19.80582px 19.12626px 0 4.46667px,
            var(--primary-color) 20.5108px 18.468px 0 4.4px,
            var(--primary-color) 21.1939px 17.78379px 0 4.33333px,
            var(--primary-color) 21.85416px 17.07434px 0 4.26667px,
            var(--primary-color) 22.49067px 16.34043px 0 4.2px,
            var(--primary-color) 23.10251px 15.58284px 0 4.13333px,
            var(--primary-color) 23.68881px 14.80241px 0 4.06667px,
            var(--primary-color) 24.24871px 14px 0 4px,
            var(--primary-color) 24.7814px 13.1765px 0 3.93333px,
            var(--primary-color) 25.28607px 12.33284px 0 3.86667px,
            var(--primary-color) 25.76198px 11.46997px 0 3.8px,
            var(--primary-color) 26.2084px 10.58888px 0 3.73333px,
            var(--primary-color) 26.62462px 9.69057px 0 3.66667px,
            var(--primary-color) 27.01001px 8.77608px 0 3.6px,
            var(--primary-color) 27.36392px 7.84648px 0 3.53333px,
            var(--primary-color) 27.68577px 6.90284px 0 3.46667px,
            var(--primary-color) 27.97502px 5.94627px 0 3.4px,
            var(--primary-color) 28.23116px 4.97791px 0 3.33333px,
            var(--primary-color) 28.4537px 3.99891px 0 3.26667px,
            var(--primary-color) 28.64223px 3.01042px 0 3.2px,
            var(--primary-color) 28.79635px 2.01364px 0 3.13333px,
            var(--primary-color) 28.91571px 1.00976px 0 3.06667px, var(--primary-color) 29px 0px 0 3px,
            var(--primary-color) 29.04896px -1.01441px 0 2.93333px,
            var(--primary-color) 29.06237px -2.03224px 0 2.86667px,
            var(--primary-color) 29.04004px -3.05223px 0 2.8px,
            var(--primary-color) 28.98185px -4.07313px 0 2.73333px,
            var(--primary-color) 28.88769px -5.09368px 0 2.66667px,
            var(--primary-color) 28.75754px -6.1126px 0 2.6px,
            var(--primary-color) 28.59138px -7.12863px 0 2.53333px,
            var(--primary-color) 28.38926px -8.14049px 0 2.46667px,
            var(--primary-color) 28.15127px -9.1469px 0 2.4px,
            var(--primary-color) 27.87755px -10.1466px 0 2.33333px,
            var(--primary-color) 27.56827px -11.1383px 0 2.26667px,
            var(--primary-color) 27.22365px -12.12075px 0 2.2px,
            var(--primary-color) 26.84398px -13.09268px 0 2.13333px,
            var(--primary-color) 26.42956px -14.05285px 0 2.06667px,
            var(--primary-color) 25.98076px -15px 0 2px,
            var(--primary-color) 25.49798px -15.93291px 0 1.93333px,
            var(--primary-color) 24.98167px -16.85035px 0 1.86667px,
            var(--primary-color) 24.43231px -17.75111px 0 1.8px,
            var(--primary-color) 23.85046px -18.63402px 0 1.73333px,
            var(--primary-color) 23.23668px -19.49789px 0 1.66667px,
            var(--primary-color) 22.5916px -20.34157px 0 1.6px,
            var(--primary-color) 21.91589px -21.16393px 0 1.53333px,
            var(--primary-color) 21.21024px -21.96384px 0 1.46667px,
            var(--primary-color) 20.4754px -22.74023px 0 1.4px,
            var(--primary-color) 19.71215px -23.49203px 0 1.33333px,
            var(--primary-color) 18.92133px -24.2182px 0 1.26667px,
            var(--primary-color) 18.10379px -24.91772px 0 1.2px,
            var(--primary-color) 17.26042px -25.58963px 0 1.13333px,
            var(--primary-color) 16.39217px -26.23295px 0 1.06667px,
            var(--primary-color) 15.5px -26.84679px 0 1px,
            var(--primary-color) 14.58492px -27.43024px 0 0.93333px,
            var(--primary-color) 13.64796px -27.98245px 0 0.86667px,
            var(--primary-color) 12.69018px -28.50262px 0 0.8px,
            var(--primary-color) 11.7127px -28.98995px 0 0.73333px,
            var(--primary-color) 10.71663px -29.4437px 0 0.66667px,
            var(--primary-color) 9.70313px -29.86317px 0 0.6px,
            var(--primary-color) 8.67339px -30.2477px 0 0.53333px,
            var(--primary-color) 7.6286px -30.59666px 0 0.46667px,
            var(--primary-color) 6.57001px -30.90946px 0 0.4px,
            var(--primary-color) 5.49886px -31.18558px 0 0.33333px,
            var(--primary-color) 4.41643px -31.42451px 0 0.26667px,
            var(--primary-color) 3.32401px -31.6258px 0 0.2px,
            var(--primary-color) 2.22291px -31.78904px 0 0.13333px,
            var(--primary-color) 1.11446px -31.91388px 0 0.06667px, var(--primary-color) 0px -32px 0 0px,
            var(--primary-color) -1.11911px -32.04713px 0 -0.06667px,
            var(--primary-color) -2.24151px -32.05506px 0 -0.13333px,
            var(--primary-color) -3.36582px -32.02361px 0 -0.2px,
            var(--primary-color) -4.49065px -31.95265px 0 -0.26667px,
            var(--primary-color) -5.61462px -31.84212px 0 -0.33333px,
            var(--primary-color) -6.73634px -31.69198px 0 -0.4px,
            var(--primary-color) -7.8544px -31.50227px 0 -0.46667px,
            var(--primary-color) -8.9674px -31.27305px 0 -0.53333px,
            var(--primary-color) -10.07395px -31.00444px 0 -0.6px,
            var(--primary-color) -11.17266px -30.69663px 0 -0.66667px,
            var(--primary-color) -12.26212px -30.34982px 0 -0.73333px,
            var(--primary-color) -13.34096px -29.96429px 0 -0.8px,
            var(--primary-color) -14.4078px -29.54036px 0 -0.86667px,
            var(--primary-color) -15.46126px -29.07841px 0 -0.93333px,
            var(--primary-color) -16.5px -28.57884px 0 -1px,
            var(--primary-color) -17.52266px -28.04212px 0 -1.06667px,
            var(--primary-color) -18.52792px -27.46878px 0 -1.13333px,
            var(--primary-color) -19.51447px -26.85936px 0 -1.2px,
            var(--primary-color) -20.48101px -26.21449px 0 -1.26667px,
            var(--primary-color) -21.42625px -25.53481px 0 -1.33333px,
            var(--primary-color) -22.34896px -24.82104px 0 -1.4px,
            var(--primary-color) -23.2479px -24.07391px 0 -1.46667px,
            var(--primary-color) -24.12186px -23.29421px 0 -1.53333px,
            var(--primary-color) -24.96967px -22.48279px 0 -1.6px,
            var(--primary-color) -25.79016px -21.64052px 0 -1.66667px,
            var(--primary-color) -26.58223px -20.76831px 0 -1.73333px,
            var(--primary-color) -27.34477px -19.86714px 0 -1.8px,
            var(--primary-color) -28.07674px -18.938px 0 -1.86667px,
            var(--primary-color) -28.7771px -17.98193px 0 -1.93333px,
            var(--primary-color) -29.44486px -17px 0 -2px,
            var(--primary-color) -30.07908px -15.99333px 0 -2.06667px,
            var(--primary-color) -30.67884px -14.96307px 0 -2.13333px,
            var(--primary-color) -31.24325px -13.91039px 0 -2.2px,
            var(--primary-color) -31.7715px -12.83652px 0 -2.26667px,
            var(--primary-color) -32.26278px -11.74269px 0 -2.33333px,
            var(--primary-color) -32.71634px -10.63018px 0 -2.4px,
            var(--primary-color) -33.13149px -9.5003px 0 -2.46667px,
            var(--primary-color) -33.50755px -8.35437px 0 -2.53333px,
            var(--primary-color) -33.84391px -7.19374px 0 -2.6px,
            var(--primary-color) -34.14px -6.0198px 0 -2.66667px,
            var(--primary-color) -34.39531px -4.83395px 0 -2.73333px,
            var(--primary-color) -34.60936px -3.63759px 0 -2.8px,
            var(--primary-color) -34.78173px -2.43218px 0 -2.86667px,
            var(--primary-color) -34.91205px -1.21916px 0 -2.93333px,
            var(--primary-color) -35px 0px 0 -3px, var(--primary-color) -35.04531px 1.22381px 0 -3.06667px,
            var(--primary-color) -35.04775px 2.45078px 0 -3.13333px,
            var(--primary-color) -35.00717px 3.6794px 0 -3.2px,
            var(--primary-color) -34.92345px 4.90817px 0 -3.26667px,
            var(--primary-color) -34.79654px 6.13557px 0 -3.33333px,
            var(--primary-color) -34.62643px 7.36007px 0 -3.4px,
            var(--primary-color) -34.41316px 8.58016px 0 -3.46667px,
            var(--primary-color) -34.15683px 9.79431px 0 -3.53333px,
            var(--primary-color) -33.85761px 11.001px 0 -3.6px,
            var(--primary-color) -33.5157px 12.19872px 0 -3.66667px,
            var(--primary-color) -33.13137px 13.38594px 0 -3.73333px,
            var(--primary-color) -32.70493px 14.56117px 0 -3.8px,
            var(--primary-color) -32.23675px 15.72291px 0 -3.86667px,
            var(--primary-color) -31.72725px 16.86968px 0 -3.93333px,
            var(--primary-color) -31.17691px 18px 0 -4px,
            var(--primary-color) -30.58627px 19.11242px 0 -4.06667px,
            var(--primary-color) -29.95589px 20.2055px 0 -4.13333px,
            var(--primary-color) -29.28642px 21.27783px 0 -4.2px,
            var(--primary-color) -28.57852px 22.32799px 0 -4.26667px,
            var(--primary-color) -27.83295px 23.35462px 0 -4.33333px,
            var(--primary-color) -27.05047px 24.35635px 0 -4.4px,
            var(--primary-color) -26.23192px 25.33188px 0 -4.46667px,
            var(--primary-color) -25.37819px 26.27988px 0 -4.53333px,
            var(--primary-color) -24.49018px 27.1991px 0 -4.6px,
            var(--primary-color) -23.56888px 28.0883px 0 -4.66667px,
            var(--primary-color) -22.6153px 28.94626px 0 -4.73333px,
            var(--primary-color) -21.6305px 29.77183px 0 -4.8px,
            var(--primary-color) -20.61558px 30.56385px 0 -4.86667px,
            var(--primary-color) -19.57168px 31.32124px 0 -4.93333px,
            var(--primary-color) -18.5px 32.04294px 0 -5px,
            var(--primary-color) -17.40175px 32.72792px 0 -5.06667px,
            var(--primary-color) -16.27818px 33.37522px 0 -5.13333px,
            var(--primary-color) -15.1306px 33.98389px 0 -5.2px,
            var(--primary-color) -13.96034px 34.55305px 0 -5.26667px,
            var(--primary-color) -12.76875px 35.08186px 0 -5.33333px,
            var(--primary-color) -11.55724px 35.56951px 0 -5.4px,
            var(--primary-color) -10.32721px 36.01527px 0 -5.46667px,
            var(--primary-color) -9.08014px 36.41843px 0 -5.53333px,
            var(--primary-color) -7.81748px 36.77835px 0 -5.6px,
            var(--primary-color) -6.54075px 37.09443px 0 -5.66667px,
            var(--primary-color) -5.25147px 37.36612px 0 -5.73333px,
            var(--primary-color) -3.95118px 37.59293px 0 -5.8px,
            var(--primary-color) -2.64145px 37.77443px 0 -5.86667px,
            var(--primary-color) -1.32385px 37.91023px 0 -5.93333px;
        animation: whirly-loader 1.25s infinite linear;
        transform-origin: 50% 50%;
    }
}
//animations start
/* ----------------------------------------------
 * Generated by Animista on 2024-4-23 17:54:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/* ----------------------------------------------
 * Generated by Animista on 2024-4-24 13:26:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

 @-webkit-keyframes scale-up-center{0%{-webkit-transform:scale(.5);transform:scale(.5)}100%{-webkit-transform:scale(1);transform:scale(1)}}@keyframes scale-up-center{0%{-webkit-transform:scale(.5);transform:scale(.5)}100%{-webkit-transform:scale(1);transform:scale(1)}}
 @-webkit-keyframes rotate-scale-down{0%{-webkit-transform:scale(1) rotateZ(0);transform:scale(1) rotateZ(0)}50%{-webkit-transform:scale(.5) rotateZ(180deg);transform:scale(.5) rotateZ(180deg)}100%{-webkit-transform:scale(1) rotateZ(360deg);transform:scale(1) rotateZ(360deg)}}@keyframes rotate-scale-down{0%{-webkit-transform:scale(1) rotateZ(0);transform:scale(1) rotateZ(0)}50%{-webkit-transform:scale(.5) rotateZ(180deg);transform:scale(.5) rotateZ(180deg)}100%{-webkit-transform:scale(1) rotateZ(360deg);transform:scale(1) rotateZ(360deg)}}
 @-webkit-keyframes slide-fwd-center{0%{-webkit-transform:translateZ(0);transform:translateZ(0)}100%{-webkit-transform:translateZ(160px);transform:translateZ(160px)}}@keyframes slide-fwd-center{0%{-webkit-transform:translateZ(0);transform:translateZ(0)}100%{-webkit-transform:translateZ(160px);transform:translateZ(160px)}}
 @-webkit-keyframes swing-left-bck{0%{-webkit-transform:rotateY(0);transform:rotateY(0);-webkit-transform-origin:left bottom;transform-origin:left bottom}100%{-webkit-transform:rotateY(180deg);transform:rotateY(180deg);-webkit-transform-origin:left bottom;transform-origin:left bottom}}@keyframes swing-left-bck{0%{-webkit-transform:rotateY(0);transform:rotateY(0);-webkit-transform-origin:left bottom;transform-origin:left bottom}100%{-webkit-transform:rotateY(180deg);transform:rotateY(180deg);-webkit-transform-origin:left bottom;transform-origin:left bottom}}
 @-webkit-keyframes swing-right-fwd{0%{-webkit-transform:rotateY(0);transform:rotateY(0);-webkit-transform-origin:right;transform-origin:right}100%{-webkit-transform:rotateY(180deg);transform:rotateY(180deg);-webkit-transform-origin:right;transform-origin:right}}@keyframes swing-right-fwd{0%{-webkit-transform:rotateY(0);transform:rotateY(0);-webkit-transform-origin:right;transform-origin:right}100%{-webkit-transform:rotateY(180deg);transform:rotateY(180deg);-webkit-transform-origin:right;transform-origin:right}}
 @-webkit-keyframes flip-2-hor-top-1{0%{-webkit-transform:translateY(0) rotateX(0);transform:translateY(0) rotateX(0);-webkit-transform-origin:50% 0;transform-origin:50% 0}100%{-webkit-transform:translateY(-100%) rotateX(-180deg);transform:translateY(-100%) rotateX(-180deg);-webkit-transform-origin:50% 100%;transform-origin:50% 100%}}@keyframes flip-2-hor-top-1{0%{-webkit-transform:translateY(0) rotateX(0);transform:translateY(0) rotateX(0);-webkit-transform-origin:50% 0;transform-origin:50% 0}100%{-webkit-transform:translateY(-100%) rotateX(-180deg);transform:translateY(-100%) rotateX(-180deg);-webkit-transform-origin:50% 100%;transform-origin:50% 100%}}
 @-webkit-keyframes scale-in-center{0%{-webkit-transform:scale(0);transform:scale(0);opacity:1}100%{-webkit-transform:scale(1);transform:scale(1);opacity:1}}@keyframes scale-in-center{0%{-webkit-transform:scale(0);transform:scale(0);opacity:1}100%{-webkit-transform:scale(1);transform:scale(1);opacity:1}}
 @-webkit-keyframes slit-in-vertical{0%{-webkit-transform:translateZ(-800px) rotateY(90deg);transform:translateZ(-800px) rotateY(90deg);opacity:0}54%{-webkit-transform:translateZ(-160px) rotateY(87deg);transform:translateZ(-160px) rotateY(87deg);opacity:1}100%{-webkit-transform:translateZ(0) rotateY(0);transform:translateZ(0) rotateY(0)}}@keyframes slit-in-vertical{0%{-webkit-transform:translateZ(-800px) rotateY(90deg);transform:translateZ(-800px) rotateY(90deg);opacity:0}54%{-webkit-transform:translateZ(-160px) rotateY(87deg);transform:translateZ(-160px) rotateY(87deg);opacity:1}100%{-webkit-transform:translateZ(0) rotateY(0);transform:translateZ(0) rotateY(0)}}
 @-webkit-keyframes slide-in-bottom{0%{-webkit-transform:translateY(1000px);transform:translateY(1000px);opacity:0}100%{-webkit-transform:translateY(0);transform:translateY(0);opacity:1}}@keyframes slide-in-bottom{0%{-webkit-transform:translateY(1000px);transform:translateY(1000px);opacity:0}100%{-webkit-transform:translateY(0);transform:translateY(0);opacity:1}}
//animations end
.apexcharts-tooltip {
    color: #000!important
}
.minus_margin {
    margin: -15px;
    height: calc(100% + 30px);
}
.justify-content-end .btn, .btn-primary {background-color: var(--primary-color)!important; border-color: var(--primary-color)!important; color: var(--primary-text-color, #fff)!important; &:hover {color: #fff;}}
.w-90 {
    width: 90% !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none!important;
  margin: 0!important;
}
.toggleTable.false {
    .minVal, .maxVal, .sumVal {
        display: none;
    }
}
.mdc-linear-progress__bar-inner {border-color: var(--primary-color)!important;}
.toggleTable.true {
    .minVal, .maxVal, .sumVal {
        display: table-cell;
    }
}
.custom-marker-label {
    background-color: #FF5733; /* Your desired background color */
    color: white; /* Text color */
    padding: 4px 8px; /* Padding around the label text */
    border-radius: 4px; /* Rounded corners */
    font-size: 12px; /* Font size */
  }
img {max-width: 100%;}
.toggleTable {
    th {
        vertical-align: middle;
    }
    td:not(:first-child):nth-child(4n+1) {
        background: rgba(0,0,0,0.2);
    }
    th, td {
        padding: 5px 10px!important;
    }
    .stick {
        min-width: 90px;
        &.mw120 {
            min-width: 120px;
        }
    }
    tr.mat-header-row {
        border-bottom-color: var(--primary-color);
    }
    td:not(:first-child) {
        text-align: center!important;
    }
    .minVal, .maxVal, .sumVal {
        font-size: 12px;
        color: rgba(0,0,0,0.6);
    }
    tr.rws {
        visibility: collapse;
        display: none;
        position: sticky;
        top: 43px;
        z-index: 1;
        border-bottom: 1px solid #000;
        td:first-child {
            z-index: 1;
            position: sticky;
            left: 0;
        }
    }
}
.toggleTable th:first-child {
    min-width: 125px;
    position: sticky;
    left: 0;
}
.duration {
    .mat-form-field-appearance-legacy .mat-form-field-infix {
        display: flex;
    }
}
td.stick {
    position: sticky;
    left: 0;
    // background: inherit!important;
}
.toggleTable.true {
    .minVal, .maxVal, .sumVal {
        visibility: visible;
    }
    tr.rws {
        visibility: visible;
        display: table-row;
    }
}
.overviewName {
    padding-top: 2px!important;
    position: relative;
    .main_text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    span {
        display: block;
        opacity: 0.5;
        font-size: 11px;
        line-height: 11px;
        margin-bottom: 5px;
    }
}
.theme-activityedit-form
{
    .description
    {
        .mat-form-field-infix
        {
            background-color: #A9D18E42;
         }
    }
}
.mat-select-panel {
    background: #fff;
    overflow: hidden!important;
    max-height: initial!important;
    // max-width: none!important;
    .mat-form-field-appearance-legacy .mat-form-field-underline {
        display: none!important;
    }
    .mat-input-element::placeholder {
        text-align: center;
        color: #333!important;
    }
    .mat-form-field.mat-form-field-hide-placeholder {
        .mat-form-field-infix {
          .mat-input-element::placeholder {
            color: #333 !important;
            -webkit-text-fill-color: #333 !important;
          }
        }
      }
    .mat-form-field {
        padding: 0 10px;
        margin: auto!important;
        background: var(--primary-color-light);
        .mat-form-field-infix {
            border: none;
            padding: 12px 0;
        }
    }
    .mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end {
        border: none;
    }
    
    .d-flex {
        background: var(--primary-color-light);
        // margin: 0 2px;
        border-top-left-radius: 4px;
        .mat-form-field {
            background: none!important;
        }
        &.text-center {
            background: inherit;
        }
    }
    .type-name {
        color: var(--primary-color); font-weight: bold;
    }
    .type-name.green {
        color: var(--primary-color)!important; font-weight: bold!important;
    }
    .mat-option-text {
        color: #000;
        display: flex;
        justify-content: space-between!important;
        align-items: center;
        .type-name {
            color: inherit;
            font-weight: normal;
            max-width: calc(100% - 60px);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    .mat-form-field-flex {
        background: rgba(255,255,255,0.5);
        border: 2px solid #000;
        border-radius: 5px!important;
        position: relative;
        top: 10px!important;
        padding: 0!important;
        input::placeholder {
            text-align: center;
        }
        .mat-form-field-infix input {
            border: none;
            box-shadow: none;
            width: 100%;
            margin: auto;
            padding: 0 10px;
        }
    }
   
    .mat-form-field.mat-focused .mat-form-field-ripple {
        display: none!important;
    }
}
.tbw .mat-form-field-flex {
    background: #fff;
}

.custom-apex-tooltip {
      background: rgba(255, 255, 255, 0.9);
      border: 1px solid #e3e3e3;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 5px;
      font-size: 12px;
    }

    .custom-apex-tooltip-y-group:nth-child(2) {
        margin: 0 10px;
    }

    .custom-apex-tooltip-title {
      font-weight: bold;
    }

    .custom-apex-tooltip-series-group {
      display: flex;
    }

    .custom-apex-tooltip-marker {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      display: inline-block;
      border-radius: 50%;
    }

    .custom-apex-tooltip-text {
      font-family: Helvetica, Arial, sans-serif;
      font-size: 12px;
      display: flex;
    }

    .custom-apex-tooltip-text-label {
      font-weight: bold;
    }

    .custom-apex-tooltip-text-value {
      margin-left: 5px;
    }

    /*header*/
    #page-main-header .nav-menus {
        display: grid;
        grid-template-columns: repeat(5, min-content);
        justify-content: end;
        // background-color: #000;
    }
    
    #page-main-header .nav-menus>li {
        border: none;
        font-family: Roboto;
    }

    div#page-main-header::before {
        content: '';
        left: -255px;
        width: 255px;
        position: absolute;
        height: 100%;
        background: var(--bar-color);
    }
    
    #page-main-header .create-farm {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #page-main-header .edit-create {
        display: flex;
        align-items: center;
        justify-content: space-around;
        justify-self: baseline;
        width: 30rem;
        h5 {
            margin: 0;
            color: white;
            padding: 0.5rem 1rem 0.5rem;
        }
        button {
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    #page-main-header {
        height: 60px;
        // background-color: rgba(43, 103, 76, 0.8);
        .lots span.search {
            position: absolute;
            right: 2%;
            transform: translate(-50%, -50%);
            top: 50%;
            .fa-search {
                color: white;
            }
            .fa-times {
                cursor: pointer;
                color: #000;
            }
        }
    
        .mobile-filters {
            justify-self: flex-start;
            margin-left: 10px;
            position: relative;
            .mat-select-trigger {
                cursor: pointer !important;
            }
    
            .add_circle {
                display: flex;
                align-items: center;
    
                .rounded-circle {
                    width: 33px;
                    height: 33px;
                    position: relative;
                    cursor: pointer;
                    &.r {
                      height: 35px;
                      width: 35px;
                      // background-color: var(--primary-color-light)!important;
                      background-size: 20px!important;
                      &::before {
                        display: none;
                      }
                    }
                    &.invoice_in {
                      background-image: url('/../../../assets/images/invoice_in.png')!important;
                      background-repeat: no-repeat!important;
                      background-position: center center!important;
                    }
                    &.invoice_out {
                      background-image: url('/../../../assets/images/invoice_out.png')!important;
                      background-repeat: no-repeat!important;
                      background-position: center center!important;
                    }
                    &.internal_note {
                      background-image: url('/../../../assets/images/note.png')!important;
                      background-repeat: no-repeat!important;
                      background-position: center center!important;
                    }
                    &.all_docs {
                      background-image: url('/../../../assets/images/list.png')!important;
                      background-repeat: no-repeat!important;
                      background-position: center center!important;
                    }
                    &.production_note {
                      background-image: url('/../../../assets/images/package.png')!important;
                      background-repeat: no-repeat!important;
                      background-position: center center!important;
                    }
                    &.config {
                      background-image: url('/../../../assets/icons/device_config.png')!important;
                      background-repeat: no-repeat!important;
                      background-position: center center!important;
                    }
                }
                .rounded-circle:before {
                    content: "+";
                    top: 50%;
                    left: 50%;
                    position: absolute;
                    font-size: 2rem;
                    transform: translate(-50%, -50%);
                }
            }
            .add_dropdown {
                width: 133px;
                height: 86px;
                position: absolute;
                top: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
    
                div {
                    padding: 0.7rem;
                    width: 100%;
                    cursor: pointer;
                    color: #fff;
                }
            }
        }
        .language-dropdown.onhover-show-div:before, .language-dropdown.onhover-show-div:after {
            right: 5px;
            left: initial;
        }
    }
    
    @media screen and (max-width: 767px) {
      // #page-main-header .edit-create button {font-size: 12px;}
      #page-main-header .edit-create {width: auto!important;}
      .mobBtn {
        width: 33px;
        height: 33px;
        padding: 0!important;
        font-size: 18px!important;
        margin-right: 10px;
    }
    }
    #page-main-header .main-header-right {
        display: grid;
        grid-template-columns: min-content 1fr max-content;
        justify-items: center;
        padding: 0 30px;
    }
    /*header*/

.apexcharts-text tspan {font-size: 11px!important;}
apx-chart > div:first-child {height: 100%; min-height: auto!important;}
graph-percentage-widget .apexcharts-legend{position: absolute!important; left: auto!important; right: auto!important; top: auto!important; bottom: 0!important; .apexcharts-legend-series {display: inline-block;}}
/*widgets*/

    ngx-masonry {
    display: flex!important;
    flex-wrap: wrap!important;
    width: 100%!important;
    height: auto!important;
    div.\*lux5GMLWat  {order: 2; width: 100%!important;}
    div.\*lux5GSat  {order: 3; width: 100%!important;}
    div.cropeyesimple   {order: 1; width: 100%!important;}
    }
    div[ngxmasonryitem] {
        position: relative!important;
        left: auto!important;
        top: auto!important;
        // width: 100%!important;
    }


.widget_categories {
    background: var(--secondary-color);
    position: sticky;
    top: 60px;
    z-index: 2;
    // min-width: 220px;
    button {
        color: var(--secondary-text-color);
        border: none;
        font-size: 16px;
        padding: 10px 15px;
        font-weight: bold;
        background: transparent;
        &.active {
            color:var(--primary-color);
        }
    }
}

.masonry-with-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-height: 10000px;
  }

  /*dashboard*/
  .dashboard-card {
    width: 100%;
    // border: 1px solid $sidebar-hover-txt-color;
    // margin-bottom: 30px;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 1px 5px 24px 0 rgba(2, 136, 209, 0.05);
    h1 {
      font-size: 20px;
      color: var(--text-color);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: normal;
      letter-spacing: -1px;
    }
    h2 {
      font-size: 16px;
      // font-weight: bold;
    //   color: $sidebar-hover-txt-color;
      text-align: right;
    }
    table {
      th {
        // background-color: $secondary-color;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        // color: $sidebar-hover-txt-color;
        border-bottom: 1px solid $primary-color;
        text-transform: lowercase;
        padding: 0 5px;
        &:not(:first-child) {
          text-align: center;
        }
        &::first-letter {
          text-transform: uppercase;
        }
      }
      td {
        font-size: 13px;
        // color: $sidebar-hover-txt-color;
        border-bottom: 1px solid $primary-color;
        &:not(:first-child) {
          text-align: right;
        }
      }
    }
  }
  /*dashboard*/

  .light_bg {
    background-color: var(--primary-color-light);
    }

  @media screen and (max-width: 580px) {
    .bgrow {padding: 1px 5px;}
  }

@media only screen and (min-width:501px) and (max-width:767px){
    .g{
        width:calc(100% / 2 - 2 * 5px);
        max-width:calc(100% - 2 * 5px)
    }
    .s11{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 50px!important;
        .dashboard-card, .dash_cont {
            min-height: 50px!important;
        }
    }
    .s12{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 100px!important;
        .dashboard-card, .dash_cont {
            min-height: 100px!important;
        }
    }
    .s13{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 150px!important;
        .dashboard-card, .dash_cont {
            min-height: 150px!important;
        }
    }
    .s14{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 200px!important;
        .dashboard-card, .dash_cont {
            min-height: 200px!important;
        }
    }
    .s15{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 250px!important;
        .dashboard-card, .dash_cont {
            min-height: 250px!important;
        }
    }
    .s16{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 300px!important;
        .dashboard-card, .dash_cont {
            min-height: 300px!important;
        }
    }
    .s17{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 350px!important;
        .dashboard-card, .dash_cont {
            min-height: 350px!important;
        }
    }
    .s18{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 400px!important;
        .dashboard-card, .dash_cont {
            min-height: 400px!important;
        }
    }
    .s21{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 50px!important;
        .dashboard-card, .dash_cont {
            min-height: 50px!important;
        }
    }
    .s22{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 100px!important;
        .dashboard-card, .dash_cont {
            min-height: 100px!important;
        }
    }
    .s23{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 150px!important;
        .dashboard-card, .dash_cont {
            min-height: 150px!important;
        }
    }
    .s24{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 200px!important;
        .dashboard-card, .dash_cont {
            min-height: 200px!important;
        }
    }
    .s25{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 250px!important;
        .dashboard-card, .dash_cont {
            min-height: 250px!important;
        }
    }
    .s26{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 300px!important;
        .dashboard-card, .dash_cont {
            min-height: 30px!important;
        }
    }
    .s27{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 350px!important;
        .dashboard-card, .dash_cont {
            min-height: 350px!important;
        }
    }
    .s28{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 400px!important;
        .dashboard-card, .dash_cont {
            min-height: 400px!important;
        }
    }
}

@media (min-width: 768px) {
    * {
      scrollbar-width: thin;
      scrollbar-color: #d1d1d1 transparent;
    }
  
    *::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  
    *::-webkit-scrollbar-thumb {
      background-color: #d1d1d1;
      border-radius: 5px;
    }
  }

@media only screen and (min-width:768px) and (max-width:1280px){
    .g{
        width:calc(100% / 3 - 2 * 5px);
        max-width:calc(100% - 2 * 5px)
    }
    .m11{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    .m12{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    .m13{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    .m14{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    .m15{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    .m16{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    .m17{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    .m18{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    .m21{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    .m22{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    .m23{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    .m24{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    .m25{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    .m26{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    .m27{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    .m28{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    .m31{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    .m32{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    .m33{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    .m34{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    .m35{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    .m35{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    .m36{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    .m37{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    .m38{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    .m41{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    .m42{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    .m43{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    .m44{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    .m45{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    .m46{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    .m47{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    .m48{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
}

@media only screen and (min-width:1281px) {
    .g{
        width:calc(100% / 4 - 2 * 5px);
        max-width:calc(100% - 2 * 5px)
    }
    // .l1{
    //     width:calc(100% / 4 * 1 - 2 * 5px)
    // }
    // .l2{
    //     width:calc(100% / 4 * 2 - 2 * 5px)
    // }
    // .l3{
    //     width:calc(100% / 4 * 3 - 2 * 5px)
    // }
    // .l4{
    //     width:calc(100% / 4 * 4 - 2 * 5px)
    // }
    .example-box, .field {
    &.xl1{
        width:calc(100% / 12 * 1 - 2 * 5px)
    }
    &.xl2{
        width:calc(100% / 12 * 2 - 2 * 5px)
    }
    &.xl3{
        width:calc(100% / 12 * 3 - 2 * 5px)
    }
    &.xl4{
        width:calc(100% / 12 * 4 - 2 * 5px)
    }
    &.xl5{
        width:calc(100% / 12 * 5 - 2 * 5px)
    }
    &.xl6{
        width:calc(100% / 12 * 6 - 2 * 5px)
    }
    &.xl7{
        width:calc(100% / 12 * 7 - 2 * 5px)
    }
    &.xl8{width:calc(100% / 12 * 8 - 2 * 5px)
    }
    &.xl9{width:calc(100% / 12 * 9 - 2 * 5px)
    }
    &.xl10{width:calc(100% / 12 * 10 - 2 * 5px)
    }
    &.xl11{width:calc(100% / 12 * 11 - 2 * 5px)
    }
    &.xl12{width:calc(100% / 12 * 12 - 2 * 5px)
    }




    &.l11{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l12{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l13{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l14{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l15{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l16{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l17{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l18{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l21{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l22{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l23{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l24{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l25{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l26{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l27{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l28{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l31{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l32{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l33{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l34{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l35{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l36{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l37{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l38{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l41{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l42{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l43{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l44{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l45{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l46{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l47{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l48{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l51{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l52{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l53{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l54{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l55{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l56{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l57{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l58{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l61{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l62{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l63{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l64{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l65{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l66{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l67{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l68{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l71{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l72{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l73{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l74{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l75{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l76{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l77{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l78{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l81{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l82{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l83{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l84{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l85{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l86{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l87{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l88{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l91{
        width:calc(100% / 12 * 9 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l92{
        width:calc(100% / 12 * 8 - 9 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l93{
        width:calc(100% / 12 * 8 - 9 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l94{
        width:calc(100% / 12 * 8 - 9 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l95{
        width:calc(100% / 12 * 8 - 9 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l96{
        width:calc(100% / 12 * 8 - 9 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l97{
        width:calc(100% / 12 * 9 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l98{
        width:calc(100% / 12 * 9 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l101{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l102{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l103{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l104{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l105{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l106{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l107{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l108{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l111{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l112{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l113{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l114{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l115{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l116{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l117{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l118{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l121{
        width:calc(100% / 12 * 12 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l122{
        width:calc(100% / 12 * 12 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l123{
        width:calc(100% / 12 * 12 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l124{
        width:calc(100% / 12 * 12 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l125{
        width:calc(100% / 12 * 102 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l126{
        width:calc(100% / 12 * 12 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l127{
        width:calc(100% / 12 * 12 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l128{
        width:calc(100% / 12 * 12 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
}
.bgrow .example-box fap-pool-widget .dash_cont {
    width: auto!important;
}

.example-box.pool {height: auto!important;}

}

.pac-container {
    z-index: 10000;
}

.position_info span {
    color: #FDD836;
}

@media screen and (max-width: 992px) {
    .example-box {
        &.s11, &.s12, &.s13, &.s14, &.s15, &.s21, &.s22, &.s23, &.s24, &.s25, &.s26, &.s27, &.s28 {
        min-height: 300px!important;
        height: auto!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
            .row {
                justify-content: space-between;
                [class*='col'] {
                    width: auto;
                }
            }
        }
    } }
    .example-box {margin: 5px 0 0!important; &:last-child {margin-bottom: 5px;}}
}

@media screen and (max-width: 767px){
    // *::-webkit-scrollbar {width: 2px;}
    svg.feather.feather-more-horizontal {color: #fff;}
}

/*widgets*/
ngx-masonry div[ngxmasonryitem] {margin: 0 5px 10px!important;}
.slash ~ .slash {display: none!important;}
.mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {background: transparent;}
.mat-optgroup-label {height: auto!important;}
.mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 2px!important;
    background: #000;
}
.custom_tree.mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0px!important;
}
.mat-dialog-container {padding: 0!important;}
.mat-form-field-appearance-legacy .mat-form-field-flex {align-items: end}
.tbg .mat-form-field-appearance-legacy .mat-form-field-flex {background: var(--primary-color-light);}
.mat-form-field-appearance-legacy .mat-form-field-infix{
    padding: 12px 3px 5px;
}
.mat-form-field .mat-datepicker-toggle .mat-mdc-button-base {vertical-align: initial!important;}
.custom_scroll_wrap {
    min-width: 220px;
    overflow-y: scroll;
    max-height: 300px;
}
.custom_tree.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {display: none;}
.custom_tree {
    .mat-select-trigger .mat-select-value {opacity: 0;}
    position: relative;
    mat-select-trigger {
    position: absolute;
    left: 0;
    top: 44%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    }
}
.mild_opacity {opacity: 0.4;}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {background: var(--primary-color);}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label{margin: 5px 2px!important; transform: translateY(-1.28125em) scale(0.85) perspective(100px) translateZ(0.00106px);}
.bx {height: 185px; width: 140px; padding: 2px; border: 1px solid var(--secondary-color); &::before {background: #ededed;}}
.bx .mat-form-field-appearance-legacy .mat-form-field-wrapper {height: 100%; padding: 0;}
.bx .mat-form-field-flex, .combo_box .mat-form-field-flex {height: 100%;}
.combo_box > * {height: 100%;}
.combo_box .mat-form-field-appearance-legacy .mat-form-field-wrapper {height: 100%;}
.rw.h10 {height: 10px;}
.simulations table th span, .simulations table td span {
    display: block;
}
.scroll_wrap {
    overflow-y: scroll;
    max-height: 200px;
}
.custom_box {padding: 5px 10px!important;
    .custom_title {
        font-size: 1.1rem!important;
    }
}
.custom_padding {padding: 10px;}
.custom_header, .card-header {
    .custom_title, p {
        font-size: 1.1rem!important;
        font-weight: 500;
    }
}
.cust_pad_fields {
    padding: 10px 5px 10px 10px;
}

.auth-bg {
    // background-image: url('../src/assets/images/fap/Background.jpg');
    position: relative;
    .bg_img {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
        background-size: cover;
        background-attachment: fixed;
    }
}

.mat-select-panel .mat-option-text .type-name.ddd {
    overflow: initial;
    max-width: 100%;
}

.card-header {
    min-height: 50px;
    max-height: 50px;
    padding: 0 10px;
}
fap-image-list {width: 100%;}
.main_combo {
    .mat-form-field-flex, .mat-form-field-wrapper,
    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        height: 100%;
    }
}
.mat-form-field.disbld {
    .mat-form-field-infix {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.2);
            z-index: 4;
        }
    }
}
.cut-down {
    .equipment-form {
        display: block!important;
    }
    .half_width {
        width: 48%;
        float: left;
        margin-right: 4%;
        &:nth-child(even) {
            margin-right: auto;
        }
    }
}
.mat-form-field-ripple {
    background: transparent!important;
}
.mat-datepicker-content-touch {margin: auto!important; width: max-content; max-width: 296px;}
.mat-datepicker-content-touch .mat-calendar{height: auto!important; width: 296px!important;}
.dashboard-card .d-flex.mx-2 {width: 100%;}
.d-flex.no-color {background: none!important;}
.fa.fa-plus {
    &::before{
        content: "+";
        position: relative;
        top: -2px;
        font-size: 33px;
        font-family: monospace;
        font-weight: bolder;
    }
}
.mat-optgroup-label {
        // display: flex !important;
        .actions{
            margin-left: auto;
            i{
                cursor: pointer;
                font-size: 20px;
            }
            .fa-trash{
                color: var(--primary-color)!important;
            }
            .fa-edit{
                color: green;
            }
        }
    }
    .input-group{overflow: hidden;}
    i.fa-trash {
        cursor: pointer;
        color: var(--primary-color);
    }
    .actionBtn span:nth-child(2) i.fa-trash {color: #212529!important;}
.mat-form-field-appearance-fill .mat-form-field-underline::before {background: transparent;}
.combo_box, .custom_border {
    .mat-form-field-appearance-legacy {
      .mat-form-field-underline {
        background: none !important;
        height: 0 !important;
      }
  
    //   .mat-form-field-infix {
    //     padding: 4px;
    //   }
  
    //   .mat-select-value-text {
    //     font-weight: 600;
    //   }
  
    //   .mat-form-field-label {
    //     transform: none !important;
    //     font-size: 10px !important;
    //     position: absolute;
    //     top: 4px;
    //     width: 100% !important;
    //     color: var(--primary-color);
    //   }
    }
  }
.dash_cont {width: 100%;}
.cut-down, .col-xl-4.col-5, .col-xl-3.col-4 {
    // .s11, .s12, .s13, .s14, .s15,
    // .s21, .s22, .s23, .s24, .s25, 
    // .m11, .m12, .m13, .m14, .m15, 
    // .m21, .m22, .m23, .m24, .m25, 
    // .m31, .m32, .m33, .m34, .m35, 
    // .l11, .l12, .l13, .l14, .l15,
    // .l21, .l22, .l23, .l24, .l25, 
    // .l31, .l32, .l33, .l34, .l35,
    // .l41, .l42, .l43, .l44, .l45,
    // .l51, .l52, .l53, .l54, .l55, 
    // .l61, .l62, .l63, .l64, .l65
    // {
    //     width: auto!important;
    // }
    .dashboard-soil-card {
        min-width: 320px;
        height: 100%;
        table td {
            height: auto;
        }
    }
}
ng-image-slider {
    height: calc(100% - 30px)!important;
}
.card-header-title {
    span {
        font-weight: normal;
    }
}
.dashboard-card {
    // max-height: calc(100% - 30px);
    position: relative;
    height: 100%;
    h1 {
        cursor: all-scroll;
    }
    table th {
        background: var(--secondary-color);
    }
}
.dashboard-soil-card h1 {
    cursor: all-scroll;
}
.custom_border {
    z-index: 1;
    &.col-6 {
        &::after {
            width: calc(100% - 30px);
        }
    }
    &::after {
        content: '';
        border-bottom: 3px solid #000;
        bottom: 17px;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}
.ng-image-slider .ng-image-slider-container .main, .ng-image-slider {height: 100%!important;}
.position-relative.crop-eye-img {
    height: calc(100% - 48px);
    display: flex;
    img {
        height: auto !important;
        width: 100%;
        max-height: 100%;
    }
}
fap-soil-widget table {height: calc(100% - 30px);}
.bgrow .example-box fap-pool-widget .dash_cont {height: auto!important; width: auto!important; border-radius: 0;}
fap-image-slider-widget .row:nth-child(2){height: calc(100% - 60px); position: relative; z-index: 1;}
// dashboard-soil-card .dash_cont {height: calc(100% - 30px);}
fap-image-slider-widget .row.border-green-a, .bg-green-b.row.mx-0 {height: auto!important;}
.dash_cont .d-flex:nth-child(1) .col-md-7, .dash_cont .d-flex:nth-child(1) .col-md-6, .dash_cont .d-flex:nth-child(1) .col-md-4:nth-child(2) {opacity: 0; transition: .5s ease all;}
.dash_cont .d-flex:nth-child(1) .col-md-4:nth-child(1):hover, .dash_cont .d-flex:nth-child(1) .col-md-3:nth-child(1):hover {
    & ~ div {
        opacity: 1;
    }
}
.dashboard-soil-card table th {max-width: 50px;}
.bg_white {background: #fff;}
.bg_pink {background: $bg_pink;}
.bg_gray {background: $bg_gray;}
.bg_yellow {background: $bg_yellow;}
.bg_green {background: $bg_green;}
.bg_brown {background: $bg_brown;}
.bg_orange {background: $bg_orange;}
.bg_blue {background: $bg_blue;}
.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

.conf_icon {
    display: inline-block;
    padding: 2px 0 2px 15px;
    cursor: pointer;
    i {
        display: block;
        width: 4px;
        height: 4px;
        background: #333;
        &:not(:last-child) {
            margin-bottom: 3px;
        }
    }
}
.col-md-2.text-right {padding-right: 0;}
td.name {text-align: left!important;}
.nimg .delt_btn i {box-shadow: rgba(169, 209, 142, 0.2);}
.dashboard-card .d-flex:first-child .col-md-4{&:hover ~ .col_lot_name{opacity: 1!important;} flex: auto; max-width: 80%!important;}
.col_lot_name {position: absolute!important; left: 0; top: 36px;
    width: auto!important; box-shadow: 0 0 10px 1px rgba(0,0,0,0.2); z-index: 2;
    padding: 10px;
    background: #fff; h2 {text-align: left!important; margin: 0;}}
    .custom_modal input {width: 100%!important;}
    .img_place_bg {border: 2px solid #d0d0d0; background: #fff; padding: 1px; border-radius: 5px; display: flex; align-items: center; justify-content: center; &.ph {background: #fff!important;} }
    .user_im {img {background: transparent; width: 60px; height: 80px; object-fit: contain;} width: 68px; height: 84px; padding: 2px; }
    img.portrait {min-width: 140px; object-fit: contain!important;}
    img.landscape {min-width: 200px; object-fit: contain!important}
*{letter-spacing: -0.02em!important;}
.dashboard-card table.toggleTable  th{span {display: block; font-weight: bold; color: var(--primary-color); text-transform: uppercase;}}
fap-culture-edit{width: 100%;}
.toggle_head {
    position: sticky;
    top: 0;
    z-index: 1;
}
.toggleTable.true tr.rws td {padding: 0 5px!important;}
.new_menu {
    position: absolute;
    right: 0;
    top: 0px;
    background: #fff;
    box-shadow: 0 0 10px 1px rgba(0,0,0,0.3);
    padding: 15px;
    min-width: 160px;
    opacity: 0;
    visibility: hidden;
    z-index: 100;
    transition: .5s ease all;
    &.true {
        top: 27px;
        opacity: 1;
        visibility: visible;
    }
    li {
        cursor: pointer;
        font-size: 14px;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        .mock_check {
          width: 15px;
          height: 15px;
          border: 1px solid #000;
          display: inline-block;
          margin-right: 5px;
          position: relative;
          transition: 0.5s ease all;
          background: transparent;
          vertical-align: middle;
          &::before {
              content: "\2713";
              position: absolute;
              width: 80%;
              transition: 0.5s ease all;
              opacity: 0;
              left: 1px;
              top: -4px;
              color: var(--primary-text-color);
          }
          &.active {
              background: var(--primary-color);
              &::before {
                  opacity: 1;
              }
          }
        }
    }
}
.adjustable_div .devices_table {
    height: calc(100% - 25px)!important;
}
.devices_table {
    th {
        // &:not(:first-child) span {
        //     text-align: center;
        //     &::before {
        //         content: '(';
        //     }
        //     &::after {
        //         content: ')';
        //     }
        // } 
        span {
            display: block;
            position: relative;
            text-transform: capitalize;
        }
    }
    td {
        .box {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            overflow: hidden;
        }
        span.lots {
            color: gray;
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .divider {
            padding: 0 5px;
        }
        span {
            color: gray;
            &.unit {
                padding-left: 5px;
            }
            &.small_text {
                font-size: 12px;
                display: block;
                span {
                    padding-left: 5px;
                }
            }
            &.btn {
                background-color: var(--primary-color);
                border: none;
                border-radius: 50%;
                width: 33px;
                padding: 7px;
                height: 33px;
                text-align: center;
            }
            ~ span {display: block; font-size: 12px;}
            img {
                max-width: 30px;
                cursor: pointer;
            }
        }
    }
}
.product_type_name {
    font-size: 12px;
    line-height: 12px;
    display: inline-block!important;
    padding: 5px 10px;
    border-radius: 4px;
    margin-top: 4px;
    background: #f0f0f0;
    &:empty {
        padding: 0;
    }
}
i.fa.fa-edit, i.fa.fa-trash {color: var(--primary-color)!important;}
.card-header {
    margin-bottom: 0;
    padding: 10px;
}
.nc {overflow-x: hidden;}
.mat-list-base .mat-list-item .mat-list-item-content{padding: 0 10px!important;}
// Gantt Chart styles //
.gtaskname div, .gtaskname, .gadditional, .gres, .gdur, .gcomp, .gcost, .gtaskheading, .gstartdate div, .gplanstartdate, .gplanenddate, .gstartdate, .genddate, .gtaskheading, .gmajorheading, .gminorheading, .gtaskcellwkend, .gtaskcellcurrent, .gminorheadingwkend {font-size: 0.8rem;}
.gmainleft {width: 50%;}
.gmainleft, .gmainright {resize: none;}
// .gtasktable td, .gtasktableh td, .gcharttableh td, .gcharttable td {padding: 5px 10px;}
// .gcharttableh td > div {min-height: 14px;}
.gcharttable, .gcharttableh {table-layout: auto;}
.apexcharts-canvas {max-height: 100%!important; height: 100%!important;}
// .gtaskcelldiv {min-height: 14px;}
// Gantt Chart styles //
.card-text-link {
        text-decoration: underline;
        color: var(--primary-color);
        cursor: pointer;
}
.extra_pages {
    padding: 15px;
}
.custom-white-marker-label {
    background: #fff;
    padding: 5px;
    border-radius: 15px;
}
.devices_table td span.custom_btn_img {
    img {
        max-width: 100%;
    }
}
.has_image_value {
    display: flex;
    justify-content: center;
    img {
        max-width: 33px;
        object-fit: contain;
    }
    div:nth-child(2) {
        padding-left: 10px;
    }
}
.auth-bg .authentication-box .card {
    background: transparent;
    .card-body {
        background-color: rgba(255,255,255,0.9);
    }
}

.with_units {
    .mat-form-field-appearance-legacy .mat-form-field-underline {background: transparent!important;}
    &::before {
        content: '';
        position: absolute;
        bottom: 16px;
        background: var(--dark-text);
        height: 2px;
        width: 100%;
    }
    .col-9 {
        padding-right: 5px!important;
    }
    .col-3 {
        color: var(--primary-color);
    }
    .w5 {
        margin-top: 18px;
        margin-left: 2px;
    }
}
.pos_rel {
    position: relative;
    .datetimepicker {
        position: absolute;
        right: 10px;
    }
}
.dark_bg {
    // .mat-form-field-appearance-legacy .mat-form-field-label{
    //     color: var(--dark-text);
    // }
    .mat-select-value {
        color: var(--dark-text);
    }
    .mat-form-field-appearance-legacy .mat-form-field-underline {
        background: var(--dark-text);
    }
    .mat-select-arrow {
        color: var(--dark-text);
    }
}

div .btn-success, div .btn-danger {
    background: var(--primary-color, #22af47)!important;
    border-color: var(--primary-color, #22af47)!important;
    &:hover {
        background: var(--primary-color, #22af47)!important;
        border-color: var(--primary-color, #22af47)!important;
    }
}

.custom_html {
    padding: 10px;
    position: relative;
    margin-bottom: 55px;
    height: calc(100% - 43px);
    & + .btns {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        text-align: center;
    }
}
.col-form-label.float {color: rgba(0, 0, 0, 0.54)}
::ng-deep .mat-paginator-page-size, .mat-mdc-paginator-page-size {
    display: none!important;
  }

  .cdk-overlay-pane {background: #fff!important; max-width: 96vw!important;}

.mat-tab-label {min-width: 30px!important;}
.mat-tab-body-content {overflow: hidden!important;}
.mat-tab-label-active {
    color: var(--primary-text-color);
    background: var(--primary-color);
    opacity: 1!important;
}
.mat-ink-bar {display: none!important;}
.page-wrapper .page-main-header {z-index: 31!important;}
.no_bg {background: transparent!important;}
.mat-mdc-icon-button:disabled {opacity: 0.2!important;}
.mat-error {
    font-size: 0.75rem!important;
    position: absolute;
    bottom: -18px;
    left: 2px;
    strong {
        font-weight: normal!important;
    }
}
//form row styles
@for $i from 1 through 10 {
    .row-#{$i} {
      height: 60px * $i;
    //   max-height: 46px * $i;
    }
  }

  span.mat-option-text .name {
    max-width: calc(100% - 65px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    &.full_width {
        max-width: 100%;
        justify-content: space-between;
    }
}

.sen_list +.scroll_wrap .mat-option-text {justify-content: normal!important}

@media only screen and (max-width: 991px) {
    .page-wrapper .page-main-header .main-header-right .nav-right {
        position: relative!important;
        padding: 0;
        // right: -5px;
    }
    .page-main-header .main-header-right {
        display: flex!important;
    }
}

@media screen and (max-width: 768px) {
    .ng-image-slider .ng-image-slider-container .main, .ng-image-slider {height: 180px!important;}
    .position-relative.crop-eye-img {height: calc(100% - 82px);}
    .nc {width: 100%!important;}
    .example-box {width: 100%!important; position: unset!important; #parent {min-height: 250px;}}
    td.dateTime, .dashboard-card table th:first-child{min-width: 65px;}
    .switch-sm .switch {margin-right: 0!important;}
}
@media screen and (max-width: 767px) {
    #page-main-header .edit-create h5 {display: none;}
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu {margin: 0 0 30px!important;}
    // .sidebar-footer {background-color: var(--primary-color);}
    fap-upload-avatar{width: 100%; height: 215px!important; display: inline-block; margin-bottom: -15px;}
    .cdk-overlay-pane {max-width: 94vw!important}
    .nc {width: 100%!important; overflow: auto!important;}
    .post_card {margin-bottom: 10px;}
    ngx-masonry div[ngxmasonryitem] {margin: 0 0 10px!important;}
    scheduler-edit .col-12 {padding-left: 10px; padding-right: 10px;}
    .cust_pad_fields {padding: 10px;}
    .mat-select-panel {max-width: 300px!important;}
    .mat-option-text .name.t {width: calc(100% - 54px);}
    .custom_header .custom_title, .custom_header p, .card-header .custom_title, .card-header p, .header .custom_title, .header p, .card-title, .card-title h4, .title h5 {font-size: 1.1rem!important;}
    ngx-masonry{display: block!important;}
    div.dashboard-card h1 {font-size: 18px;}
    img.portrait {min-width: 140px;}
    img.landscape {min-width: 200px;}
    .fa.fa-plus::before{top: 0}
}
@media screen and (max-width: 580px) {
    .c-eye {max-height: 200px; overflow: auto;}
    .position-relative.crop-eye-img {height: calc(100% - 40px);}
    #page-main-header .lots span.search{right: -10px!important;}
    .dashboard-card{padding: 10px 10px!important;}
    fap-chart-widget, graph-range-widget, graph-percentage-widget {
        .dashboard-card{overflow: hidden;}
        .row {padding: 0 10px;}
    }
    // fap-chart-widget .cheat_row {width: calc(100% + 20px); margin-left: -10px;}
    ngx-masonry {height: auto!important;}
    .s11, .s12, .s13, .s14, .s15, .s16, .s17, .s18, .s21, .s22, .s23, .s24, .s25, .s26, .s27, .s28 {
        height: 300px;
       .dash_cont {
        height: 300px;
       }
    }
    .s11, .s12, .s13, .s14, .s15, .s16, .s17, .s18, .s21, .s22, .s23, .s24, .s25, .s26, .s27, .s28 {
        &.pool {
            height: auto;
        }
        fap-pool-widget {
            .dash_cont {
                height: auto;
            }
        }
    }
    .example-box {#parent {min-height: 200px; height: 100%;}}
    .cut-down .half_width {width: 100%; float: none; margin-right: 0;}
    fap-alert-widget {height: 100%; #parent{height: 100%!important;}}
    $widget-selector: fap-crop-eye-simple-widget, fap-chart-widget graph-range-widget, graph-percentage-widget;

    @for $i from 11 through 28 {
        .s#{$i} #{$widget-selector} .dashboard-card, .s#{$i} #{$widget-selector} .dash_cont {
            height: 320px;
        }
    }
    // .dashboard-card .d-flex .col-md-2{padding-right: 15px!important;}
    .bgrow {padding: 10px!important;}
    .example-box #parent{overflow: hidden;}

    fap-table-widget div[class^='s'], fap-table-widget div[class*=' s']{ 
        height: 300px!important;
    }
    // .page-wrapper .page-main-header .main-header-right .nav-right {
    //     right: 10px;
    // }
}