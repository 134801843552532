$custom-file-text: ();
@import "bootstrap/scss/bootstrap";
@import "theme/variables";
@import "style";
@import '../../assets/scss/variables/variables.scss';

body {
    font-family: Roboto, sans-serif;
}

.ter-container {
    background-color: #fff;
    padding: 0;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px !important;
}

.white-background {
    background-color: #FFFFFF !important;
}

// .grey-background {
//     background-color: #C4C4C4 !important;
// }
.border-green-a{
    border: 2px solid var(--secondary-color);
}
.line-height-pt3{
    line-height: 1.3;
}
.bg-green-a{
    background: rgba(169, 209, 142, 0.3);
}
.border-left-green-a{
    border-left:5px solidvar(--primary-color);
}
.border-left-blue-a{
    border-left:5px solid #000AFF;
}
.border-left-orange-a{
    border-left:5px solid #FF9900;
}
.letter-spacing-pt2em{
    letter-spacing: -0.02em;
}
.w-91{
    width: 91%;
}
.color-red-a{
    color:#FF0000;
}
.pad-1px{
    padding: 0px 1px;
}
.round-btn{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.4);
}
.round-btn-green{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: var(--primary-color);
}
.round-btn-right{
    padding: 5px 8px;
    right: 26%;
    transform: translate(-50%, 0);
    position: absolute;
    top: 20%;
img{
    transform: rotate(90deg);
    width: 17px;
    cursor: pointer;
}
}
.round-btn-left{
    padding: 5px 7px;
    left: 38%;
    transform: translate(-50%, 0);
    position: absolute;
    top: 20%;
img{
    transform: rotate(270deg);
    width: 17px;
    cursor: pointer;
}
}
.round-btn-up{
    padding: 5px 9px;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    top: 4%;
img{
    width: 17px;
    cursor: pointer;
}
}
.round-btn-down{
    padding: 7px 9px;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    top: 35%;
img{
    transform: rotate(180deg);
    width: 17px;
    cursor: pointer;
}
}
.round-btn-autorotate{
    padding: 7px 8px;
    right: 4%;
    position: absolute;
    top: 4%;
    cursor: pointer;
}
.crop-eye-img{
    canvas{
        width: 100% !important;
        height: 100% !important;
        position: unset !important;
    }
}
.main .main-inner .img-div img {
    height: 100%!important;
    border-radius: 0!important;
}
.bg-green-b{
    background: var(--primary-color-light);
}
.bg-green-c{
   background:var(--primary-color);
}
.bg-blur{
    background: rgba(0, 0, 0, 0.4);
    // position: absolute;
    // top: 4%;
    // left: 2%;
    // display: inline-block;
}
.border-green-select{
    border: 1px solid #333333;
}
.round-btn-green-left{
    padding: 5px 7px;
   
img{
    transform: rotate(270deg);
    width: 17px;
    cursor: pointer;
}
}
.round-btn-green-right{
    padding: 5px 10px;
    
img{
    transform: rotate(90deg);
    width: 17px;
    cursor: pointer;
}
}
.bs-select:focus{
        border-color:transparent;
        box-shadow: none;
    
}
.add_circle_a {
    display: flex;
    align-items: center;

    .rounded-circle {
        width: 33px;
        height: 33px;
        position: relative;
        cursor: pointer;
        color: #000!important; background-color: var(--tertiary-color);
    }
    .rounded-circle:before {
        content: "+";
        top: 50%;
        left: 50%;
        position: absolute;
        font-size: 2rem;
        transform: translate(-50%, -50%);
    }
}
@media (max-width: 576px) {
    .ter-container {
        padding: 0;
        margin-bottom: 50px !important;
    }
}
.crop-eye-bg{
    background-image: url("/../../assets/images/crop-eye-img.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
 