$primary-color: rgb(43, 103, 76);
$primary-text-color: rgb(255, 255, 255);
$primary-color-light: rgba(169, 209, 142, 0.5);
$secondary-color: rgba(169, 209, 142, 1);
$secondary-text-color: rgb(0, 0 ,0);
$text-color: rgb(0, 0 ,0);
$tertiary-color: rgb(235, 255, 0);
$tertiary-text-color: rgba(43, 103, 76, 0.8);
$text-color-light: rgb(128, 128, 128);
$border-radius: 3px;
$bar-color: rgb(64, 53, 59);

$primary-color-dark: darken($primary-color, 15%);

$secondary-color-light: lighten(#776609, 40%);
$success-color: color("green");
$error-color: color("red");
$warning-color: #ebd534;
$link-color: color("light-blue");

$background-color: #A3D092;
$background-color-transparent: rgba(163, 208, 146, 0.7);
